import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Box, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Layout, Meta, Section } from "components";

const useStyles = makeStyles((theme) => ({
  teamspeakInfo: {
    "& > *": {
      marginBottom: theme.spacing(5),
    },
  },
  teamspeakImage: {
    margin: "0 auto",
  },
  teamspeakSectionWrapper: {
    backgroundColor: theme.palette.common.black,
  },
  teamspeakSection: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",

    "& > *": {
      flex: `1 1 ${theme.spacing(35)}px`,
      margin: `0 ${theme.spacing(2)}px`,
    },
  },
}));

export const teamspeakImageQuery = graphql`
  query TeamspeakImage {
    file(relativePath: { eq: "teamspeak-security.png" }) {
      childImageSharp {
        gatsbyImageData(
          height: 360,
          quality: 80,
          placeholder: NONE
        )
      }
    }
  }`;

/**
 * Render join page.
 * @returns {JSX.Element} component
 */
export default function Page() {
  const data = useStaticQuery(teamspeakImageQuery);
  const classes = useStyles();
  const email = "support@semtekdivision.com";
  return (
    <Layout>
      <Meta
        title="Join"
        description="Talk to us through Teamspeak or email us with inquiries."
      />
      <Section
        id="teamspeak"
        className={classes.teamspeakSection}
        wrapperClasses={{ root: classes.teamspeakSectionWrapper }}
      >
        <Box style={{ display: "flex" }}>
          <GatsbyImage
            image={data.file.childImageSharp.gatsbyImageData}
            alt="3D vault room with Teamspeak logo"
            className={classes.teamspeakImage}
          />
        </Box>
        <Box classes={{ root: classes.teamspeakInfo }}>
          <Typography variant="h2" align="center">
            Talk with us
          </Typography>
          <Typography variant="h3" align="center">
            We use Teamspeak 5 for secure encrypted communication.
          </Typography>
          <Typography align="center">
            Teamspeak 5 is currently in closed beta.
            <br />
            However, our servers are backwards compatible with Teamspeak 3.
          </Typography>
          <Typography variant="h4" align="center">
            <Link href="//teamspeak.com/en/downloads">
              Go to teamspeak.com to download
            </Link>
          </Typography>
          <Typography variant="h4" align="center">
            <Link
              href="ts3server://ts.semtekdivision.com"
              variant="h4"
            >
              Connect to ts.semtekdivision.com
            </Link>
          </Typography>
        </Box>
      </Section>
      <Section>
        <Typography variant="h2" align="center">
          Send us an email
        </Typography>
        <Typography align="center">
          Reach out to us for administrative and community inquries.
        </Typography>
        <Box style={{ width: "100%", textAlign: "center" }}>
          <Link
            href={`mailto:${email}`}
            variant="h4"
            align="center"
          >
            { email }
          </Link>
        </Box>
      </Section>
    </Layout>
  );
}
